<template>
  <div>
    <div class="user-panel">
      <img class="avatar" :src="form.avatar || 'https://pics.tide.moreless.io/avatar/Fvut7zZlzpasbn9Uw-FZGzDpcM53'" />
      <div class="userinfo">
        <h3>{{ form.nickname }} ( {{ form.id }} | {{ form.sid }} )</h3>
        <p>
          <span v-if="form.vip.is_valid"> PLUS </span>
          <span v-if="form.scene_pass.is_valid"> SCENE_PASS </span>
          <span>注册: {{ $moment(form.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>{{ $moment().diff($moment(form.created_at * 1000), 'days') }} 天</span>
          <span>更新：{{ $moment(form.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
          <span>
            <a @click="showJSON">查看JSON</a>
          </span>
        </p>
      </div>
    </div>
    <el-menu class="nav-tab" mode="horizontal" router :default-active="defaultActive">
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/edit`">用户信息</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/vip_info`">会员信息</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/settings`">用户设置</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/favorites`">用户收藏</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/records`">功能记录</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/usages_task`">使用记录</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/purchased`">已购场景</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/mixed_scenes`">混音场景</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/transactions_new`">交易记录</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/payments`">支付记录</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/iap_notifications`">应用内购记录</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/session`">Session</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/debug`">DEBUG</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/iap_receipts`">应用内购收据（D）</el-menu-item>
      <el-menu-item :index="`/users/manager/${$route.params.user_id}/receipts`">苹果收据（D）</el-menu-item>
    </el-menu>
    <el-dialog title="用户数据" :visible.sync="JSONVisible">
      <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UsersPageHeader',
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      JSONVisible: false,
      detail: '',
    };
  },
  computed: {
    defaultActive() {
      return this.$route.fullPath;
    },
  },
  methods: {
    showJSON() {
      this.detail = JSON.stringify(this.form, undefined, 2);
      this.JSONVisible = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.nav-tab {
  margin-bottom: -18px;
}
.nav-tab .el-menu-item {
  line-height: 45px;
  height: 45px;
}
.user-panel {
  display: flex;
  margin-bottom: 16px;
}
.userinfo {
  position: relative;
  top: 4px;
  margin-left: 24px;
  h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 12px;
  }
  p {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    font-size: 14px;
  }
  span + span:before {
    content: ' | ';
  }
}
.avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
</style>
