<template>
  <container>
    <div slot="page-header-extra">
      <page-header :form="form"></page-header>
    </div>
    <div slot="container" class="container">
      <div class="container-body-header">
        <div class="container-body-header-title">收据记录（新）</div>
      </div>
      <div class="container-body">
        <el-table :data="list" v-infinite-scroll="fetch" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="50">
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="retry_times" label="更新时间">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.updated_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="channel" label="渠道">
            <template slot-scope="scope">
              <span>{{ scope.row.channel }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="from" label="渠道">
            <template slot-scope="scope">
              <span>{{ scope.row.from }}</span>
            </template>
          </el-table-column>

          <el-table-column width="160" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showJSON(scope.row)">JSON</el-button>
              <el-button type="text" @click="showCollect(scope.row)">恢复购买</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog title="JSON" :visible.sync="JSONVisible">
          <el-input v-model="detail" type="textarea" :autosize="{ minRows: 8 }"></el-input>
        </el-dialog>
        <el-dialog title="回收会员" :visible.sync="CollectVisible">
          <el-table :data="restoreDetail.dup_user_infos" v-if="restoreDetail.dup_user_infos">
            <el-table-column label="ID">
              <template slot-scope="scope">
                <span>{{ scope.row.sid }} / {{ scope.row.user_id}}</span>
              </template>
            </el-table-column>
            <el-table-column label="昵称" prop="nickname"></el-table-column>
            <el-table-column label="邮箱" prop="login_methods"></el-table-column>
            <el-table-column label="手机号" prop="extra_info.partial_phone_number"></el-table-column>
            <el-table-column label="更新时间">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.update_at * 1000).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="CollectVisible = false">取 消</el-button>
            <el-button type="primary" @click="clawVip">回收</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </container>
</template>

<script>
import Container from '@/components/container';
import PageHeader from './page-header';
export default {
  name: 'EditEquip',
  components: {
    Container,
    PageHeader,
  },
  data() {
    return {
      busy: false,
      finish: false,
      JSONVisible: false,
      CollectVisible: false,
      list: [],
      detail: '',
      restoreDetail: {},
      form: {
        id: '',
        email: '',
        nickname: '',
        sex: 1,
        birthday: 0,
        email_verified: false,
        nation_code: '',
        phone_number: '',
        avatar: '',
        wechat: {
          access_token: '',
          openid: '',
          unionid: '',
        },
        facebook: {
          uid: '',
          access_token: '',
        },
        weibo: {
          access_token: '',
          uid: '',
        },
        qq: {
          access_token: '',
          openid: '',
        },
        created_at: 0,
        updated_at: 0,
        is_pwd_set: false,
        vip: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        scene_pass: {
          expired_at: 0,
          created_at: 0,
          updated_at: 0,
        },
        reg_by_guest: false,
      },
    };
  },
  computed: {
    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      handler(val) {
        this.init();
      },
    },
  },
  methods: {
    async clawVip() {
      if (this.restoreDetail.dup_user_infos.length === 0) {
        this.$message.error('没有可回收的会员');
        return;
      }
      if (confirm('确定回收会员吗？')) {
        await this.$request({
          url: `/v1/admin/users/${this.$route.params.user_id}/claw`,
          method: 'POST',
          data: {
            user_ids: this.restoreDetail.dup_user_infos.map(item => item.user_id),
          },
        });
        this.$message.success('回收成功');
        this.CollectVisible = false;
        this.refetch();
      } else {
        this.$message.info('已取消');
      }
    },
    showJSON(data) {
      this.detail = JSON.stringify(data, undefined, 2);
      this.JSONVisible = true;
    },
    async showCollect(data) {
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/iap_notifications/${data._id}/restore`,
        method: 'POST',
      });
      this.restoreDetail = res.data;
      this.CollectVisible = true;
    },
    refetch() {
      this.list = [];
      this.finish = false;
      this.fetch();
    },
    fetch: async function () {
      this.busy = true;
      const limit = 50;
      const res = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/iap_notifications`,
        params: {
          limit,
          offset: this.list.length,
        },
      });
      const list = res.data;
      if (list.length < limit) {
        this.finish = true;
      }
      this.list = [...this.list, ...list];
      const $container = document.getElementById('main-container');
      const srcollTop = $container.scrollTop;
      this.$nextTick(() => {
        $container.scrollTop = srcollTop;
        this.busy = false;
      });
    },
    init: async function () {
      const usersRes = await this.$request({
        url: `/v1/admin/users/${this.$route.params.user_id}/profile`,
      });
      this.form = Object.assign({}, this.form, usersRes.data);
    },
  },
  created() {
    this.init();
  },
};
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang="less" scoped>
.add-btn {
  width: 100%;
  border-style: dashed;
}

.list-item {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.list-item > * {
  flex-basis: 120px;
}
.list-item + .list-item {
  border-top: 1px solid #e8e8e8;
}
.list-main-item {
  flex-basis: 480px;
}

.avatar {
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  align-self: center;
  flex-shrink: 0;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background-color: gray;
}

.list-item-name {
  color: rgba(0, 0, 0, 0.65);
}

.list-item-actions {
  align-self: center;
  a {
    color: #1890ff;
    cursor: pointer;
    &:hover {
      color: #40a9ff;
    }
  }
}
</style>
