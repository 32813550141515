<template>
  <container custom-style="paddingBottom: 80px">
    <div slot="container">
      <div class="container-footer">
        <el-button :disabled="!$store.getters.hasEditRole" type="primary" size="small" class="save-btn" @click="save">保存</el-button>
      </div>
    </div>
    <div slot="container" class="container">
      <div class="container-head">
        <div class="container-head-title">图文配置</div>
        <div class="container-head-extra">
          <el-button type="text" @click="syncDataToOtherLang">将英文及简中填充至其他语言</el-button>
        </div>
      </div>
      <el-form class="form container-body" v-model="xForm">
        <el-form-item>
          <div class="form-container">
            <el-tabs class="tabs-container" type="border-card" v-model="activeLang">
              <el-tab-pane v-for="lang in langs" :label="lang" :name="lang" :key="lang">
                <el-col :span="23">
                  <el-form-item label="标题">
                    <el-input v-model="xForm.title[lang]"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="23">
                  <el-form-item label="副标题">
                    <el-input v-model="xForm.sub_title[lang]"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="23">
                  <el-form-item label="描述">
                    <el-input type="textarea" :rows="4" v-model="xForm.description[lang]"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="23">
                  <el-form-item label="标签">
                    <el-input v-model="xForm.tag[lang]"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="23">
                  <el-form-item label="文章地址">
                    <el-input :rows="4" v-model="xForm.url[lang]"></el-input>
                  </el-form-item>
                </el-col>
              </el-tab-pane>
            </el-tabs>
            <div class="iframe-container">
              <div class="iframe-container-title">
                <span>图文预览</span>
                <router-link :disabled="!xForm.id" :to="{ name: 'EditMedArticleContent' }">编辑</router-link>
              </div>
              <iframe id="result" ref="result"></iframe>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="快速填充" style="margin-bottom: 0">
          <el-input type="textarea" :rows="2" v-model="shortcut" placeholder="中文标题, 中文副标题, 中文描述, 中文标签, 英文标题, 英文副标题, 英文描述, 英文标签"></el-input>
        </el-form-item>

        <el-form-item label="加入标签[手动]">
          <el-col>
            <el-select v-model="xForm.manual_simple_tag_ids" value-key="id" multiple filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="加入标签[自动]">
          <el-col>
            <el-select v-model="xForm.simple_tag_ids" value-key="id" multiple disabled filterable default-first-option placeholder="选择标签" style="width: 100%">
              <el-option v-for="item in simpleTagOptions" :key="item.value.id" :label="getTR(item.value.name) + ' | ' + item.value.type + ' | ' + item.value.remark" :value="item.value.id"></el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="上架地区">
          <regions-selecter v-model="xForm.regions"></regions-selecter>
        </el-form-item>
        <el-form-item label="上架语言：">
          <languages-selecter v-model="xForm.languages"></languages-selecter>
        </el-form-item>

        <el-form-item>
          <div slot="label" class="form-label">
            <span>图片</span>
            <i class="el-icon-edit" @click="dialogDisplay.imageUrlEdit = true"></i>
          </div>
          <el-col>
            <div class="preview" v-if="xForm.image" @click="dialogDisplay.imagePreview = true">
              <div class="preview-img">
                <cover :width="100" :height="100" :src="xForm.image" :mask-colors="xForm.mask_colors"></cover>
              </div>
              <div class="preview-img-cover">
                <i class="material-icons">remove_red_eye</i>
                <span>预览</span>
              </div>
            </div>
            <el-upload v-else action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
              <div class="uploadPic">
                <i class="el-icon-upload2"></i>
                <span>上传图片</span>
              </div>
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item label="主色调">
          <color-picker v-model="xForm.primary_color"></color-picker>
        </el-form-item>

        <el-row>
          <el-col :span="10">
            <el-form-item label="遮罩颜色渐变1">
              <color-picker v-model="xForm.mask_colors[0]"></color-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="遮罩颜色渐变2">
              <color-picker v-model="xForm.mask_colors[1]"></color-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="状态：">
          <status-radio v-model="xForm.status"></status-radio>
        </el-form-item>

        <el-form-item label="是否允许分享：">
          <el-radio-group v-model="xForm.is_can_share">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否允许评论：">
          <el-radio-group v-model="xForm.is_can_comment">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-dialog title="图片预览" :visible.sync="dialogDisplay.imagePreview">
        <img class="preview-dialog-img" :src="xForm.image" />
      </el-dialog>

      <el-dialog title="图片地址" :visible.sync="dialogDisplay.imageUrlEdit">
        <el-form label-position="top" label-width="120px">
          <el-form-item>
            <el-input v-model="xForm.image">
              <i v-if="imageUploading" class="el-icon-loading" slot="suffix"></i>
              <el-upload v-else slot="suffix" action="https://upload.qiniup.com" :on-progress="uploadImageProgress" :on-error="uploadImageError" :on-success="uploadImageSuccess" :before-upload="beforeUpload" :data="{ token: picToken }" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDisplay.imageUrlEdit = false">返 回</el-button>
        </span>
      </el-dialog>
    </div>
  </container>
</template>

<script>
/* eslint-disable */
import draft from '@/assets/draft.txt';
import Container from '@/components/container';
import Draggable from 'vuedraggable';
import Cover from '@/components/cover';
import ColorPicker from '@/components/color-picker';
import RegionsSelecter from '@/components/regions-selecter';
import LanguagesSelecter from '@/components/languages-selecter';
import OpenCC from '@/plugins/opencc';
import StatusRadio from '@/components/status-radio';

import markdownit from 'markdown-it';
import markdownItFootnote from 'markdown-it-footnote';
import markdownItSup from 'markdown-it-sup';
import markdownItMark from 'markdown-it-mark';
import markdownItAttrs from 'markdown-it-attrs';
import markdownItInlineComments from 'markdown-it-inline-comments';
import markdownItImplicitFigures from 'markdown-it-implicit-figures';

const md = markdownit({
  html: true,
  linkify: false,
  typographer: false,
});

md.renderer.rules.table_open = function () {
  return '<table class="table table-striped">\n';
};

md.renderer.rules.paragraph_open = function (tokens, idx) {
  var line;
  var token = tokens[idx];
  if (token.map && token.level === 0) {
    line = token.map[0];
    return '<p class="line" data-line="' + line + '">';
  }
  return '<p>';
};

md.renderer.rules.heading_open = function (tokens, idx) {
  var line;
  var token = tokens[idx];
  if (token.map && token.level === 0) {
    line = token.map[0];
    return '<' + token.tag + ' class="line" data-line="' + line + '">';
  }
  return '<' + token.tag + '>';
};

md.use(markdownItFootnote);
md.use(markdownItSup);
md.use(markdownItMark);
md.use(markdownItInlineComments);
md.use(markdownItAttrs);
md.use(markdownItImplicitFigures, {
  dataType: true, // <figure data-type="image">, default: false
  figcaption: true, // <figcaption>alternative text</figcaption>, default: false
  tabindex: true, // <figure tabindex="1+n">..., default: false
  link: false, // <a href="img.png"><img src="img.png"></a>, default: false
});

export default {
  name: 'AlbumForm',
  components: {
    Container,
    Draggable,
    Cover,
    ColorPicker,
    RegionsSelecter,
    LanguagesSelecter,
    StatusRadio,
  },
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    const env = this.$env;
    return {
      objectTagOptions: [],
      simpleTagOptions: [],
      picToken: '',
      picCDN: '',
      shortcut: '',
      loading: false,
      imageUploading: false,
      env,
      langs: require('@/langs.json'),
      activeLang: 'zh-Hans',

      dialogDisplay: {
        imagePreview: false,
        imageUrlEdit: false,
      },
    };
  },
  computed: {
    xForm: {
      get() {
        return this.form;
      },
      set(newForm) {
        this.$emit('change', newForm);
        this.render();
      },
    },

    infiniteScrollDisabled() {
      return this.busy || this.finish;
    },

    idoc() {
      var iwindow = this.$refs.result.contentWindow;
      var idoc = iwindow.document;
      return idoc;
    },
  },
  watch: {
    shortcut(val) {
      const shortcutInfo = val.split('	');
      console.log(shortcutInfo);
      console.log('--- shortcutInfo ---');
      console.log(shortcutInfo.length);
      if (shortcutInfo.length !== 8) {
        this.$message.error('解析失败，无法填充');
      } else {
        let [zhHansTitle, zhHansSubtitle, zhHansDescription, zhHansTag, enName, enSubtitle, enDescription, enTag] = shortcutInfo;

        zhHansTitle = zhHansTitle
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        zhHansSubtitle = zhHansSubtitle
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        zhHansDescription = zhHansDescription
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        zhHansTag = zhHansTag
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();

        enName = enName
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        enSubtitle = enSubtitle
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        enDescription = enDescription
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();
        enTag = enTag
          .replace(/^\"|\"$/g, '')
          .replace(/^↵|↵$/g, '')
          .trim();

        this.$nextTick(() => {
          this.form.title['zh-Hans'] = zhHansTitle;
          this.form.title.en = enName;

          this.form.sub_title['zh-Hans'] = zhHansSubtitle;
          this.form.sub_title.en = enSubtitle;

          this.form.description['zh-Hans'] = zhHansDescription;
          this.form.description.en = enDescription;

          this.form.tag['zh-Hans'] = zhHansTag;
          this.form.tag.en = enTag;

          this.syncDataToOtherLang();
        });
      }
    },
    activeLang() {
      this.render();
    },
    form: {
      deep: true,
      handler() {
        this.render();
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.form);
    },
    async fetchTags() {
      this.objectTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/object_tags',
        params: { type: 'article' },
      });
      this.objectTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },
    async fetchSimpleTags() {
      this.simpleTagOptions = [];
      const res = await this.$request({
        url: '/v2/admin/simple_tags',
        params: { offset: 0, limit: 512 },
      });
      this.simpleTagOptions = res.data.map((tag) => {
        return {
          value: tag,
          label: tag.name,
        };
      });
    },

    async syncDataToOtherLang() {
      const title = this.form.title['en'];
      const subTitle = this.form.sub_title['en'];
      const description = this.form.description['en'];
      const tag = this.form.tag['en'];

      this.form.title['ja'] = title;
      this.form.sub_title['ja'] = subTitle;
      this.form.description['ja'] = description;
      this.form.tag['ja'] = tag;

      this.form.title['ko'] = title;
      this.form.sub_title['ko'] = subTitle;
      this.form.description['ko'] = description;
      this.form.tag['ko'] = tag;

      this.form.title['es'] = title;
      this.form.sub_title['es'] = subTitle;
      this.form.description['es'] = description;
      this.form.tag['es'] = tag;

      this.form.title['ru'] = title;
      this.form.sub_title['ru'] = subTitle;
      this.form.description['ru'] = description;
      this.form.tag['ru'] = tag;

      await this.s2twp();

      this.$message({
        message: '覆盖成功，请手动保存',
        type: 'success',
        duration: 2000,
      });
    },

    async s2twp() {
      try {
        this.xForm.title['zh-Hant'] = this.opencc_twp(this.xForm.title['zh-Hans']);
        this.xForm.sub_title['zh-Hant'] = this.opencc_twp(this.xForm.sub_title['zh-Hans']);
        this.xForm.description['zh-Hant'] = this.opencc_twp(this.xForm.description['zh-Hans']);
        this.xForm.tag['zh-Hant'] = this.opencc_twp(this.xForm.tag['zh-Hans']);
      } catch (e) {
        this.$message.error('转换失败');
      }
    },

    render() {
      const lang = this.activeLang;
      const { title, sub_title, markdown_content } = this.xForm;
      let markdownContent = '新建完成后再编辑图文内容';
      if (this.xForm.id) {
        markdownContent = markdown_content[lang];
      }
      const context = md.render(markdownContent);
      this.$refs.result.contentWindow.postMessage(
        {
          title: title[lang],
          subtitle: sub_title[lang],
          content: context,
        },
        '*',
      );
    },

    beforeUpload: async function (file) {
      const getTokenResp = await this.$request({
        url: '/v1/admin/market/scenes/pic_upload_token',
      });
      this.picToken = getTokenResp.data.token;
      this.picCDN = getTokenResp.data.cdn;
    },
    uploadImageSuccess(response) {
      const picUrl = `${this.picCDN}/${response.key}`;
      this.xForm.image = picUrl;
      this.imageUploading = false;
    },
    uploadImageProgress(response) {
      this.imageUploading = true;
    },
    uploadImageError(response) {
      this.imageUploading = false;
    },
    getTR(langDict) {
      return langDict['zh-Hans'] || langDict['zh-Hant'] || langDict.en;
    },
  },
  created: async function () {
    this.fetchTags();
    this.fetchSimpleTags();
    this.$nextTick(() => {
      this.idoc.write(draft);
      setTimeout(() => {
        this.render();
      }, 500);
    });
    this.opencc_twp = await OpenCC.Converter('cn', 'twp');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  .container-head-extra {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .container-title {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 16px 32px;
    border-bottom: 1px solid #e8e8e8;
  }
  .container-body {
    padding: 24px;
  }
  .save-btn {
    float: right;
    margin: 12px;
  }
  .el-color-picker--small {
    vertical-align: middle;
  }
  .vertical-form {
    padding-left: 144px;
  }
}
.form-label {
  position: relative;
  z-index: 1;
}
.form-label i {
  opacity: 0;
}
.el-form-item:hover .form-label i {
  opacity: 1;
}
.form-label .el-icon-edit,
.form-label .el-icon-picture-outline {
  font-size: 14px;
}

.album-table .el-form-item {
  width: 180px;
}

.sort-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  line-height: 24px;
}
.sort-item:hover {
  background-color: #f5f7fa;
}
.sort-item + .sort-item {
  border-top: 1px solid #ebeef5;
}

.sort-item-key {
  width: 32px;
  text-align: center;
}

.sort-item-name {
  padding-left: 16px;
}

.uploadPic {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999;
}
.preview-img {
  float: left;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.preview:hover .preview-img-cover {
  opacity: 1;
}
.preview-img-cover {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.38);
  i {
    font-size: 24px;
  }
  span {
    display: block;
    font-size: 12px;
    line-height: 24px;
  }
}
.preview-dialog-img {
  width: 100%;
}

.uploadIcon {
  display: inline-block;
}

.form-container {
  display: flex;
  flex-direction: row;
}

.tabs-container {
  flex-shrink: 1;
  flex-grow: 1;
}

#result {
  width: 375px;
  border: none;
  flex-grow: 1;
}

.iframe-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}

.iframe-container-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  color: #606266;
  border: 1px solid #e4e7ed;
  background-color: #fff;
}
</style>
